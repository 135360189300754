import logo from './logo.svg';
import './App.css';
import React from 'react';
import Starfield from 'react-starfield';
import { useRef, useEffect, useState } from 'react';
import { Typewriter } from 'react-simple-typewriter'
import { Bounce, Slide, Zoom, Fade, Reveal, JackInTheBox } from "react-awesome-reveal";
import { Navbar, NavbarBrand, NavbarCollapse, NavbarLink, NavbarToggle, Timeline, Footer, FooterBrand, FooterCopyright, FooterDivider, FooterIcon, FooterTitle } from "flowbite-react";
import { HiStar, HiSparkles, HiMoon } from "react-icons/hi";
import { BsFacebook, BsInstagram, BsTwitter, BsTiktok, BsWhatsapp } from "react-icons/bs";
import { Card, CardHeader, CardFooter, Image, Button, Input, Textarea, Link } from "@nextui-org/react";
import { register } from 'swiper/element/bundle';
import emailjs from '@emailjs/browser';
register();

const RevealOnScroll = ({ children }) => {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const onWindScroll = () => {
      const element = ref.current;
      if (element) {
        const { top } = element.getBoundingClientRect();
        const isVisible = top < window.innerHeight;
        setIsVisible(isVisible);
      }
    };

    window.addEventListener("scroll", onWindScroll);
    return () => {
      window.removeEventListener("scroll", onWindScroll);
    };
  }, []);

  const classes = `transition-opacity duration-1000
      ${isVisible ? "opacity-100" : "opacity-0"
    }`;

  return (
    <div ref={ref} className={classes}>
      {children}
    </div>
  );
};

const SERVICE_ID = "service_m4oi0o2";
const TEMPLATE_ID = "template_gib8doa";
const PUBLIC_KEY = "Rwm2CvvdTsRsNqeaM";

function App() {

  const swiperElRef = useRef(null);

  useEffect(() => {
    // listen for Swiper events using addEventListener
    swiperElRef.current.addEventListener('swiperprogress', (e) => {
      const [swiper, progress] = e.detail;
      console.log(progress);
    });

    swiperElRef.current.addEventListener('swiperslidechange', (e) => {
      console.log('slide changed');
    });
  }, []);

  const handleOnSubmit = (e) => {
    e.preventDefault();
    emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, e.target, PUBLIC_KEY)
      .then((result) => {
        console.log(result.text);
        alert('Message Sent Successfully')
      }, (error) => {
        console.log(error.text);
        alert('Something went wrong!')
      });
    e.target.reset()
  };

  return (

    <div className="App">

      <Starfield
        starCount={3000}
        starColor={[255, 255, 255]}
        speedFactor={0.05}
        backgroundColor="#000000"
      />
      {

      }

      <div id="astronaut">
        <img src="https://images.vexels.com/media/users/3/152639/isolated/preview/506b575739e90613428cdb399175e2c8-space-astronaut-cartoon-by-vexels.png" alt="" />
      </div>

      <div id="astronaut2">
        <img src="https://images.vexels.com/media/users/3/152641/isolated/preview/2b3d63661f0d7fe62c36168604985f26-space-cosmonaut-cartoon-by-vexels.png" alt="" />
      </div>

      {/* menu */}
      <section>
        <Navbar fluid className='fixed w-full z-10'>
          <NavbarBrand href="#inicio">
            <img src="/resources/images/novasyncmkt-logo-horizontal.png" className="mr-3 h-6 sm:h-14" alt="NovaSync Marketing Logo" />
          </NavbarBrand>
          <div className="flex md:order-2">
            <Link href="https://api.whatsapp.com/send?phone=5219981269629&text=Hola%2C%20como%20quiero%20informaci%C3%B3n%20de%20sus%20servicios!%20%F0%9F%98%80" target='_blank'>
              <Button>Contacto</Button>
            </Link>
            <NavbarToggle />
          </div>
          <NavbarCollapse>
            <NavbarLink href="#inicio" active>
              Inicio
            </NavbarLink>
            <NavbarLink href="#somos">Nosotros</NavbarLink>
            <NavbarLink href="#servicios">Servicios</NavbarLink>
            <NavbarLink href="#portfolio">Proyectos</NavbarLink>
            <NavbarLink href="#contacto">Contacto</NavbarLink>
          </NavbarCollapse>
        </Navbar>
      </section>

      {/* header */}
      <section class="bg-center" id='inicio'>
        <div class="px-2 mx-auto max-w-screen-xl text-center py-48 lg:py-56">
          <h1 class="mb-4 text-3xl font-extrabold tracking-tight leading-none text-white md:text-5xl lg:text-9xl">
            <Fade cascade duration={200}>

              <span>&nbsp;
                {/* Style will be inherited from the parent element */}
                <Typewriter
                  words={['Marketing', 'Diseño Gráfico', 'Desarrollo Web', 'SEO']}
                  loop={15}
                  autoloop={true}
                  cursor
                  cursorStyle='_'
                  typeSpeed={100}
                  deleteSpeed={100}
                  delaySpeed={1000}
                  onLoopDone={0}
                  onType={0}
                />
              </span>
            </Fade>
          </h1>
          <p class="mb-8 text-lg font-normal text-gray-300 lg:text-2xl sm:px-16 lg:px-24">
            <Bounce>Nos enfocamos en marketing, desarrollo web y diseño gráfico para ofrecer soluciones únicas. No solo pensamos fuera de la caja, si no que pensamos fuera de esta galaxia.</Bounce>
          </p>
          <Zoom>
            <div class="flex flex-col space-y-4 sm:flex-row sm:justify-center sm:space-y-0">
              <a href="#servicios" class="inline-flex justify-center items-center py-3 px-5 text-base font-medium text-center text-white rounded-lg bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 dark:focus:ring-blue-900">
                Servicios
                <svg class="w-3.5 h-3.5 ms-2 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9" />
                </svg>
              </a>
              <a href="#contacto" class="inline-flex justify-center hover:text-gray-900 items-center py-3 px-5 sm:ms-4 text-base font-medium text-center text-white rounded-lg border border-white hover:bg-gray-100 focus:ring-4 focus:ring-gray-400">
                Contacto
              </a>
            </div>
          </Zoom>
        </div>
      </section >

      {/* servicios */}

      {/* desktop */}
      <RevealOnScroll>
        <section className='pt-24 px-5 md:px-0 hidden md:block' id='servicios'>
          <Reveal effect="slide-in-left">
            <h2 className='text-4xl md:text-7xl mx-auto max-w-screen-xl'>Exploramos Nuevos Horizontes para Tu Empresa</h2>
            <p className='pt-8 text-xl md:text-2xl mx-auto max-w-screen-xl'>
              ¿Estás listo para llevar tu marca a nuevas galaxias? Con nuestros servicios de marketing, tu negocio alcanzará las estrellas
            </p>
          </Reveal>

          <swiper-container class="py-24"
            ref={swiperElRef}
            navigation={false}
            pagination={false}
            effect={"coverflow"}
            centered-slide={true}
            slides-per-view={3}
            coverflow-effect-rotate={10}
            coverflow-effect-slide-shadows={true}
            coverflow-effect-stretch={10}
            coverflow-effect-depth={100}
            coverflow-effect-modifier={1}
            mousewheel={false}
            loop={true}
          >
            <swiper-slide>
              <Card isFooterBlurred className="w-full h-[750px] col-span-12 sm:col-span-7">
                <CardHeader className="absolute z-10 top-1 flex-col items-start">
                  <Bounce right duration={600} delay={1000}>
                    <p className="text-tiny px-4 text-white/60 uppercase font-bold">NovaSyncMKT</p>
                  </Bounce>
                  <Bounce left duration={700} delay={1400}>
                    <h4 className="text-white/90 px-4 font-medium text-4xl">Estrategias de marketing</h4>
                  </Bounce>
                  <Bounce right duration={800} delay={1800}>
                    <p className="text-md px-12 pt-6 text-white/90 uppercase font-bold">En NovaSync, convertimos tus ideas en realidades digitales. Nuestro equipo de expertos en desarrollo Sabemos que cada negocio es único y requiere un enfoque estratégico adaptado a sus necesidades y objetivos. Nuestro equipo de expertos en marketing está aquí para diseñar y ejecutar estrategias de marketing integrales que impulsen tu crecimiento y te ayuden a alcanzar tus metas comerciales</p>
                  </Bounce>
                </CardHeader>
                <Bounce right duration={1000} delay={2200}>
                  <Image
                    removeWrapper
                    style={{ backgroundColor: 'black', opacity: 0.65 }}
                    alt="Relaxing app background"
                    className="z-0 w-full h-full object-cover"
                    src="/resources/images/novasyncmkt-estrategias-mkt.webp"
                  />
                </Bounce>
                <CardFooter className="absolute px-12 bg-black/40 bottom-0 z-10 border-t-1 border-default-600 dark:border-default-100">
                  <Bounce right duration={1000} delay={2200}>
                    <div className="flex flex-grow gap-2 items-center">
                      <Image
                        alt="Breathing app icon"
                        className="rounded-full w-10 h-11 bg-black"
                        src="/resources/images/white-icon.png"
                      />
                      <div className="flex flex-col">
                        <p className="text-tiny text-white/60"></p>
                        <p className="text-tiny text-white/60"></p>
                      </div>
                    </div>
                    {/* <Button radius="full" size="sm">Saber más</Button> */}
                  </Bounce>
                </CardFooter>
              </Card>
            </swiper-slide>

            <swiper-slide>
              <Card className="w-full h-[750px] col-span-12 sm:col-span-7">
                <CardHeader className="absolute z-10 top-1 flex-col items-start">
                  <Bounce right duration={600} delay={1000}>
                    <p className="text-tiny px-4 text-white/60 uppercase font-bold">NovaSyncMKT</p>
                  </Bounce>
                  <Bounce left duration={700} delay={1400}>
                    <h4 className="text-white/90 px-4 font-medium text-4xl">Social Media</h4>
                  </Bounce>
                  <Bounce right duration={800} delay={1800}>
                    <p className="text-md px-12 pt-6 text-white/90 uppercase font-bold">Sabemos que cada negocio es único y requiere un enfoque estratégico adaptado a sus necesidades y objetivos. Nuestro equipo de expertos en marketing está aquí para diseñar y ejecutar estrategias de marketing integrales que impulsen tu crecimiento y te ayuden a alcanzar tus metas comerciales</p>
                  </Bounce>
                </CardHeader>
                <Bounce right duration={1000} delay={2200}>
                  <Image
                    blurredImg="true"
                    removeWrapper
                    style={{ backgroundColor: 'black', opacity: 0.65 }}
                    alt="Relaxing app background"
                    className="z-0 w-full h-full object-cover"
                    src="/resources/images/novasyncmkt-social-media.webp"
                  />
                </Bounce>
                <CardFooter className="absolute px-12 bg-black/40 bottom-0 z-10 border-t-1 border-default-600 dark:border-default-100">
                  <Bounce right duration={1000} delay={2200}>
                    <div className="flex flex-grow gap-2 items-center">
                      <Image
                        alt="Breathing app icon"
                        className="rounded-full w-10 h-11 bg-black"
                        src="/resources/images/white-icon.png"
                      />
                      <div className="flex flex-col">
                        <p className="text-tiny text-white/60"></p>
                        <p className="text-tiny text-white/60"></p>
                      </div>
                    </div>
                    {/* <Button radius="full" size="sm">Saber más</Button> */}
                  </Bounce>
                </CardFooter>
              </Card>
            </swiper-slide>

            <swiper-slide>
              <Card isFooterBlurred className="w-full h-[750px] col-span-12 sm:col-span-7">
                <CardHeader className="absolute z-10 top-1 flex-col items-start">
                  <Bounce right duration={600} delay={1000}>
                    <p className="text-tiny px-4 text-white/60 uppercase font-bold">NovaSyncMKT</p>
                  </Bounce>
                  <Bounce left duration={700} delay={1400}>
                    <h4 className="text-white/90 px-4 font-medium text-4xl">Social Listening</h4>
                  </Bounce>
                  <Bounce right duration={800} delay={1800}>
                    <p className="text-md px-12 pt-6 text-white/90 uppercase font-bold">
                      Te ayudamos a escuchar, comprender y actuar sobre lo que realmente importa a tus clientes. Con nuestros servicios de social listening, transformamos datos y conversaciones en valiosas oportunidades de crecimiento y mejora para tu negocio
                    </p>
                  </Bounce>
                </CardHeader>
                <Bounce right duration={1000} delay={2200}>
                  <Image
                    removeWrapper
                    style={{ backgroundColor: 'black', opacity: 0.65 }}
                    alt="Relaxing app background"
                    className="z-0 w-full h-full object-cover"
                    src="/resources/images/novasyncmkt-social-listening.webp"
                  />
                </Bounce>
                <CardFooter className="absolute px-12 bg-black/40 bottom-0 z-10 border-t-1 border-default-600 dark:border-default-100">
                  <Bounce right duration={1000} delay={2200}>
                    <div className="flex flex-grow gap-2 items-center">
                      <Image
                        alt="Breathing app icon"
                        className="rounded-full w-10 h-11 bg-black"
                        src="/resources/images/white-icon.png"
                      />
                      <div className="flex flex-col">
                        <p className="text-tiny text-white/60"></p>
                        <p className="text-tiny text-white/60"></p>
                      </div>
                    </div>
                    {/* <Button radius="full" size="sm">Saber más</Button> */}
                  </Bounce>
                </CardFooter>
              </Card>
            </swiper-slide>

            <swiper-slide>
              <Card isFooterBlurred className="w-full h-[750px] col-span-12 sm:col-span-7">
                <CardHeader className="absolute z-10 top-1 flex-col items-start">
                  <Bounce right duration={600} delay={1000}>
                    <p className="text-tiny px-4 text-white/60 uppercase font-bold">NovaSyncMKT</p>
                  </Bounce>
                  <Bounce left duration={700} delay={1400}>
                    <h4 className="text-white/90 px-4 font-medium text-4xl">Fotografía de producto</h4>
                  </Bounce>
                  <Bounce right duration={800} delay={1800}>
                    <p className="text-md px-12 pt-6 text-white/90 uppercase font-bold">Entendemos que la imagen lo es todo en el comercio electrónico y la publicidad. Nuestros servicios de fotografía de producto están diseñados para resaltar la calidad, detalles y características únicas de tus productos, capturando la atención de tus clientes y aumentando tus ventas</p>
                  </Bounce>
                </CardHeader>
                <Bounce right duration={1000} delay={2200}>
                  <Image
                    removeWrapper
                    style={{ backgroundColor: 'black', opacity: 0.65 }}
                    alt="Relaxing app background"
                    className="z-0 w-full h-full object-cover"
                    src="/resources/images/novasyncmkt-fotografia.webp"
                  />
                </Bounce>
                <CardFooter className="absolute px-12 bg-black/40 bottom-0 z-10 border-t-1 border-default-600 dark:border-default-100">
                  <Bounce right duration={1000} delay={2200}>
                    <div className="flex flex-grow gap-2 items-center">
                      <Image
                        alt="Breathing app icon"
                        className="rounded-full w-10 h-11 bg-black"
                        src="/resources/images/white-icon.png"
                      />
                      <div className="flex flex-col">
                        <p className="text-tiny text-white/60"></p>
                        <p className="text-tiny text-white/60"></p>
                      </div>
                    </div>
                    {/* <Button radius="full" size="sm">Saber más</Button> */}
                  </Bounce>
                </CardFooter>
              </Card>
            </swiper-slide>

            <swiper-slide>
              <Card isFooterBlurred className="w-full h-[750px] col-span-12 sm:col-span-7">
                <CardHeader className="absolute z-10 top-1 flex-col items-start">
                  <Bounce right duration={600} delay={1000}>
                    <p className="text-tiny px-4 text-white/60 uppercase font-bold">NovaSyncMKT</p>
                  </Bounce>
                  <Bounce left duration={700} delay={1400}>
                    <h4 className="text-white/90 px-4 font-medium text-4xl">Diseño gráfico</h4>
                  </Bounce>
                  <Bounce right duration={800} delay={1800}>
                    <p className="text-md px-12 pt-6 text-white/90 uppercase font-bold">Estamos dedicados a dar vida a tus ideas a través del diseño gráfico creativo y profesional. Ya sea que necesites una nueva identidad visual, materiales impresos impactantes o gráficos digitales atractivos, nuestro equipo de diseñadores está aquí para hacer que tu marca se destaque</p>
                  </Bounce>
                </CardHeader>
                <Bounce right duration={1000} delay={2200}>
                  <Image
                    removeWrapper
                    style={{ backgroundColor: 'black', opacity: 0.65 }}
                    alt="Relaxing app background"
                    className="z-0 w-full h-full object-cover"
                    src="/resources/images/novasyncmkt-diseno-grafico.webp"
                  />
                </Bounce>
                <CardFooter className="absolute px-12 bg-black/40 bottom-0 z-10 border-t-1 border-default-600 dark:border-default-100">
                  <Bounce right duration={1000} delay={2200}>
                    <div className="flex flex-grow gap-2 items-center">
                      <Image
                        alt="Breathing app icon"
                        className="rounded-full w-10 h-11 bg-black"
                        src="/resources/images/white-icon.png"
                      />
                      <div className="flex flex-col">
                        <p className="text-tiny text-white/60"></p>
                        <p className="text-tiny text-white/60"></p>
                      </div>
                    </div>
                    {/* <Button radius="full" size="sm">Saber más</Button> */}
                  </Bounce>
                </CardFooter>
              </Card>
            </swiper-slide>

            <swiper-slide>
              <Card isFooterBlurred className="w-full h-[750px] col-span-12 sm:col-span-7">
                <CardHeader className="absolute z-10 top-1 flex-col items-start">
                  <Bounce right duration={600} delay={1000}>
                    <p className="text-tiny px-4 text-white/60 uppercase font-bold">NovaSyncMKT</p>
                  </Bounce>
                  <Bounce left duration={700} delay={1400}>
                    <h4 className="text-white/90 px-4 font-medium text-4xl">Branding</h4>
                  </Bounce>
                  <Bounce right duration={800} delay={1800}>
                    <p className="text-md px-12 pt-6 text-white/90 uppercase font-bold">Entendemos que una marca sólida es fundamental para el éxito de cualquier negocio. Nuestros servicios de branding están diseñados para ayudarte a definir, desarrollar y fortalecer la identidad de tu marca, asegurando que cada aspecto de tu negocio reflejen tu visión y atraiga a tu audiencia ideal</p>
                  </Bounce>
                </CardHeader>
                <Bounce right duration={1000} delay={2200}>
                  <Image
                    removeWrapper
                    style={{ backgroundColor: 'black', opacity: 0.65 }}
                    alt="Relaxing app background"
                    className="z-0 w-full h-full object-cover"
                    src="/resources/images/novasyncmkt-branding.webp"
                  />
                </Bounce>
                <CardFooter className="absolute px-12 bg-black/40 bottom-0 z-10 border-t-1 border-default-600 dark:border-default-100">
                  <Bounce right duration={1000} delay={2200}>
                    <div className="flex flex-grow gap-2 items-center">
                      <Image
                        alt="Breathing app icon"
                        className="rounded-full w-10 h-11 bg-black"
                        src="/resources/images/white-icon.png"
                      />
                      <div className="flex flex-col">
                        <p className="text-tiny text-white/60"></p>
                        <p className="text-tiny text-white/60"></p>
                      </div>
                    </div>
                    {/* <Button radius="full" size="sm">Saber más</Button> */}
                  </Bounce>
                </CardFooter>
              </Card>
            </swiper-slide>

            <swiper-slide>
              <Card isFooterBlurred className="w-full h-[750px] col-span-12 sm:col-span-7">
                <CardHeader className="absolute z-10 top-1 flex-col items-start">
                  <Bounce right duration={600} delay={1000}>
                    <p className="text-tiny px-4 text-white/60 uppercase font-bold">NovaSyncMKT</p>
                  </Bounce>
                  <Bounce left duration={700} delay={1400}>
                    <h4 className="text-white/90 px-4 font-medium text-4xl">Desarrollo Web</h4>
                  </Bounce>
                  <Bounce right duration={800} delay={1800}>
                    <p className="text-md px-12 pt-6 text-white/90 uppercase font-bold">En NovaSync, convertimos tus ideas en realidades digitales. Nuestro equipo de expertos en desarrollo web está dedicado a crear sitios web impresionantes, funcionales y adaptados a tus necesidades específicas. Ya sea que necesites un sitio web corporativo, una tienda en línea o una plataforma personalizada, estamos aquí para ayudarte a alcanzar el éxito en línea.</p>
                  </Bounce>
                </CardHeader>
                <Bounce right duration={1000} delay={2200}>
                  <Image
                    removeWrapper
                    style={{ backgroundColor: 'black', opacity: 0.65 }}
                    alt="Relaxing app background"
                    className="z-0 w-full h-full object-cover"
                    src="/resources/images/novasyncmkt-web.webp"
                  />
                </Bounce>
                <CardFooter className="absolute px-12 bg-black/40 bottom-0 z-10 border-t-1 border-default-600 dark:border-default-100">
                  <Bounce right duration={1000} delay={2200}>
                    <div className="flex flex-grow gap-2 items-center">
                      <Image
                        alt="Breathing app icon"
                        className="rounded-full w-10 h-11 bg-black"
                        src="/resources/images/white-icon.png"
                      />
                      <div className="flex flex-col">
                        <p className="text-tiny text-white/60"></p>
                        <p className="text-tiny text-white/60"></p>
                      </div>
                    </div>
                    {/* <Button radius="full" size="sm">Saber más</Button> */}
                  </Bounce>
                </CardFooter>
              </Card>
            </swiper-slide>

            <swiper-slide>
              <Card isFooterBlurred className="w-full h-[750px] col-span-12 sm:col-span-7">
                <CardHeader className="absolute z-10 top-1 flex-col items-start">
                  <Bounce right duration={600} delay={1000}>
                    <p className="text-tiny px-4 text-white/60 uppercase font-bold">NovaSyncMKT</p>
                  </Bounce>
                  <Bounce left duration={700} delay={1400}>
                    <h4 className="text-white/90 px-4 font-medium text-4xl">Desarrollo de E-commerce</h4>
                  </Bounce>
                  <Bounce right duration={800} delay={1800}>
                    <p className="text-md px-12 pt-6 text-white/90 uppercase font-bold">Lleva tu negocio al siguiente nivel con una tienda en línea robusta y segura. Integramos soluciones de pago, gestión de inventarios y herramientas de marketing para que puedas vender tus productos y servicios de manera eficiente y sin complicaciones</p>
                  </Bounce>
                </CardHeader>
                <Bounce right duration={1000} delay={2200}>
                  <Image
                    removeWrapper
                    style={{ backgroundColor: 'black', opacity: 0.65 }}
                    alt="Relaxing app background"
                    className="z-0 w-full h-full object-cover"
                    src="/resources/images/novasyncmkt-ecommerce.webp"
                  />
                </Bounce>
                <CardFooter className="absolute px-12 bg-black/40 bottom-0 z-10 border-t-1 border-default-600 dark:border-default-100">
                  <Bounce right duration={1000} delay={2200}>
                    <div className="flex flex-grow gap-2 items-center">
                      <Image
                        alt="Breathing app icon"
                        className="rounded-full w-10 h-11 bg-black"
                        src="/resources/images/white-icon.png"
                      />
                      <div className="flex flex-col">
                        <p className="text-tiny text-white/60"></p>
                        <p className="text-tiny text-white/60"></p>
                      </div>
                    </div>
                    {/* <Button radius="full" size="sm">Saber más</Button> */}
                  </Bounce>
                </CardFooter>
              </Card>
            </swiper-slide>

            <swiper-slide>
              <Card isFooterBlurred className="w-full h-[750px] col-span-12 sm:col-span-7">
                <CardHeader className="absolute z-10 top-1 flex-col items-start">
                  <Bounce right duration={600} delay={1000}>
                    <p className="text-tiny px-4 text-white/60 uppercase font-bold">NovaSyncMKT</p>
                  </Bounce>
                  <Bounce left duration={700} delay={1400}>
                    <h4 className="text-white/90 px-4 font-medium text-4xl">Optimización para Motores de Búsqueda (SEO)</h4>
                  </Bounce>
                  <Bounce right duration={800} delay={1800}>
                    <p className="text-md px-12 pt-6 text-white/90 uppercase font-bold">No basta con tener un sitio web hermoso; también necesitas que te encuentren. Implementamos estrategias avanzadas de SEO para mejorar tu visibilidad en los motores de búsqueda y atraer tráfico orgánico de calidad</p>
                  </Bounce>
                </CardHeader>
                <Bounce right duration={1000} delay={2200}>
                  <Image
                    removeWrapper
                    style={{ backgroundColor: 'black', opacity: 0.65 }}
                    alt="Relaxing app background"
                    className="z-0 w-full h-full object-cover"
                    src="/resources/images/novasyncmkt-seo.webp"
                  />
                </Bounce>
                <CardFooter className="absolute px-12 bg-black/40 bottom-0 z-10 border-t-1 border-default-600 dark:border-default-100">
                  <Bounce right duration={1000} delay={2200}>
                    <div className="flex flex-grow gap-2 items-center">
                      <Image
                        alt="Breathing app icon"
                        className="rounded-full w-10 h-11 bg-black"
                        src="/resources/images/white-icon.png"
                      />
                      <div className="flex flex-col">
                        <p className="text-tiny text-white/60"></p>
                        <p className="text-tiny text-white/60"></p>
                      </div>
                    </div>
                    {/* <Button radius="full" size="sm">Saber más</Button> */}
                  </Bounce>
                </CardFooter>
              </Card>
            </swiper-slide>

          </swiper-container>
        </section>

        {/* mobile */}
        <section className='pt-24 px-5 md:px-0 block md:hidden' id='servicios'>
          <Reveal effect="slide-in-left">
            <h2 className='text-4xl md:text-7xl mx-auto max-w-screen-xl'>Exploramos Nuevos Horizontes para Tu Empresa</h2>
            <p className='pt-8 text-xl md:text-2xl mx-auto max-w-screen-xl'>
              ¿Estás listo para llevar tu marca a nuevas galaxias? Con nuestros servicios de marketing, tu negocio alcanzará las estrellas
            </p>
          </Reveal>

          <swiper-container class="py-24"
            ref={swiperElRef}
            navigation={true}
            pagination={false}
            effect={"coverflow"}
            centered-slide={true}
            slides-per-view={1}
            coverflow-effect-rotate={10}
            coverflow-effect-slide-shadows={true}
            coverflow-effect-stretch={10}
            coverflow-effect-depth={100}
            coverflow-effect-modifier={1}
            mousewheel={false}
            loop={true}
          >
            <swiper-slide>
              <Card isFooterBlurred className="w-full h-[500px] col-span-12 sm:col-span-7">
                <CardHeader className="absolute z-10 top-1 flex-col items-start">
                  <Bounce right duration={600} delay={1000}>
                    <p className="text-tiny px-4 text-white/60 uppercase font-bold">NovaSyncMKT</p>
                  </Bounce>
                  <Bounce left duration={700} delay={1400}>
                    <h4 className="text-white/90 px-4 font-medium text-4xl">Estrategias de marketing</h4>
                  </Bounce>
                  <Bounce right duration={800} delay={1800}>
                    <p className="text-sm px-12 pt-6 text-white/90 uppercase font-bold">En NovaSync, convertimos tus ideas en realidades digitales. Nuestro equipo de expertos en desarrollo Sabemos que cada negocio es único y requiere un enfoque estratégico adaptado a sus necesidades y objetivos. Nuestro equipo de expertos en marketing está aquí para diseñar y ejecutar estrategias de marketing integrales que impulsen tu crecimiento y te ayuden a alcanzar tus metas comerciales</p>
                  </Bounce>
                </CardHeader>
                <Bounce right duration={1000} delay={2200}>
                  <Image
                    removeWrapper
                    style={{ backgroundColor: 'black', opacity: 0.25 }}
                    alt="Relaxing app background"
                    className="z-0 w-full h-full object-cover"
                    src="/resources/images/novasyncmkt-estrategias-mkt.webp"
                  />
                </Bounce>
                <CardFooter className="absolute px-12 bg-black/40 bottom-0 z-10 border-t-1 border-default-600 dark:border-default-100">
                  <Bounce right duration={1000} delay={2200}>
                    <div className="flex flex-grow gap-2 items-center">
                      <Image
                        alt="Breathing app icon"
                        className="rounded-full w-10 h-11 bg-black"
                        src="/resources/images/white-icon.png"
                      />
                      <div className="flex flex-col">
                        <p className="text-tiny text-white/60"></p>
                        <p className="text-tiny text-white/60"></p>
                      </div>
                    </div>
                    {/* <Button radius="full" size="sm">Saber más</Button> */}
                  </Bounce>
                </CardFooter>
              </Card>
            </swiper-slide>

            <swiper-slide>
              <Card className="w-full h-[500px] col-span-12 sm:col-span-7">
                <CardHeader className="absolute z-10 top-1 flex-col items-start">
                  <Bounce right duration={600} delay={1000}>
                    <p className="text-tiny px-4 text-white/60 uppercase font-bold">NovaSyncMKT</p>
                  </Bounce>
                  <Bounce left duration={700} delay={1400}>
                    <h4 className="text-white/90 px-4 font-medium text-4xl">Social Media</h4>
                  </Bounce>
                  <Bounce right duration={800} delay={1800}>
                    <p className="text-sm px-12 pt-6 text-white/90 uppercase font-bold">Sabemos que cada negocio es único y requiere un enfoque estratégico adaptado a sus necesidades y objetivos. Nuestro equipo de expertos en marketing está aquí para diseñar y ejecutar estrategias de marketing integrales que impulsen tu crecimiento y te ayuden a alcanzar tus metas comerciales</p>
                  </Bounce>
                </CardHeader>
                <Bounce right duration={1000} delay={2200}>
                  <Image
                    blurredImg="true"
                    removeWrapper
                    style={{ backgroundColor: 'black', opacity: 0.25 }}
                    alt="Relaxing app background"
                    className="z-0 w-full h-full object-cover"
                    src="/resources/images/novasyncmkt-social-media.webp"
                  />
                </Bounce>
                <CardFooter className="absolute px-12 bg-black/40 bottom-0 z-10 border-t-1 border-default-600 dark:border-default-100">
                  <Bounce right duration={1000} delay={2200}>
                    <div className="flex flex-grow gap-2 items-center">
                      <Image
                        alt="Breathing app icon"
                        className="rounded-full w-10 h-11 bg-black"
                        src="/resources/images/white-icon.png"
                      />
                      <div className="flex flex-col">
                        <p className="text-tiny text-white/60"></p>
                        <p className="text-tiny text-white/60"></p>
                      </div>
                    </div>
                    {/* <Button radius="full" size="sm">Saber más</Button> */}
                  </Bounce>
                </CardFooter>
              </Card>
            </swiper-slide>

            <swiper-slide>
              <Card isFooterBlurred className="w-full h-[500px] col-span-12 sm:col-span-7">
                <CardHeader className="absolute z-10 top-1 flex-col items-start">
                  <Bounce right duration={600} delay={1000}>
                    <p className="text-tiny px-4 text-white/60 uppercase font-bold">NovaSyncMKT</p>
                  </Bounce>
                  <Bounce left duration={700} delay={1400}>
                    <h4 className="text-white/90 px-4 font-medium text-4xl">Social Listening</h4>
                  </Bounce>
                  <Bounce right duration={800} delay={1800}>
                    <p className="text-sm px-12 pt-6 text-white/90 uppercase font-bold">
                      Te ayudamos a escuchar, comprender y actuar sobre lo que realmente importa a tus clientes. Con nuestros servicios de social listening, transformamos datos y conversaciones en valiosas oportunidades de crecimiento y mejora para tu negocio
                    </p>
                  </Bounce>
                </CardHeader>
                <Bounce right duration={1000} delay={2200}>
                  <Image
                    removeWrapper
                    style={{ backgroundColor: 'black', opacity: 0.25 }}
                    alt="Relaxing app background"
                    className="z-0 w-full h-full object-cover"
                    src="/resources/images/novasyncmkt-social-listening.webp"
                  />
                </Bounce>
                <CardFooter className="absolute px-12 bg-black/40 bottom-0 z-10 border-t-1 border-default-600 dark:border-default-100">
                  <Bounce right duration={1000} delay={2200}>
                    <div className="flex flex-grow gap-2 items-center">
                      <Image
                        alt="Breathing app icon"
                        className="rounded-full w-10 h-11 bg-black"
                        src="/resources/images/white-icon.png"
                      />
                      <div className="flex flex-col">
                        <p className="text-tiny text-white/60"></p>
                        <p className="text-tiny text-white/60"></p>
                      </div>
                    </div>
                    {/* <Button radius="full" size="sm">Saber más</Button> */}
                  </Bounce>
                </CardFooter>
              </Card>
            </swiper-slide>

            <swiper-slide>
              <Card isFooterBlurred className="w-full h-[500px] col-span-12 sm:col-span-7">
                <CardHeader className="absolute z-10 top-1 flex-col items-start">
                  <Bounce right duration={600} delay={1000}>
                    <p className="text-tiny px-4 text-white/60 uppercase font-bold">NovaSyncMKT</p>
                  </Bounce>
                  <Bounce left duration={700} delay={1400}>
                    <h4 className="text-white/90 px-4 font-medium text-4xl">Fotografía de producto</h4>
                  </Bounce>
                  <Bounce right duration={800} delay={1800}>
                    <p className="text-sm px-12 pt-6 text-white/90 uppercase font-bold">Entendemos que la imagen lo es todo en el comercio electrónico y la publicidad. Nuestros servicios de fotografía de producto están diseñados para resaltar la calidad, detalles y características únicas de tus productos, capturando la atención de tus clientes y aumentando tus ventas</p>
                  </Bounce>
                </CardHeader>
                <Bounce right duration={1000} delay={2200}>
                  <Image
                    removeWrapper
                    style={{ backgroundColor: 'black', opacity: 0.25 }}
                    alt="Relaxing app background"
                    className="z-0 w-full h-full object-cover"
                    src="/resources/images/novasyncmkt-fotografia.webp"
                  />
                </Bounce>
                <CardFooter className="absolute px-12 bg-black/40 bottom-0 z-10 border-t-1 border-default-600 dark:border-default-100">
                  <Bounce right duration={1000} delay={2200}>
                    <div className="flex flex-grow gap-2 items-center">
                      <Image
                        alt="Breathing app icon"
                        className="rounded-full w-10 h-11 bg-black"
                        src="/resources/images/white-icon.png"
                      />
                      <div className="flex flex-col">
                        <p className="text-tiny text-white/60"></p>
                        <p className="text-tiny text-white/60"></p>
                      </div>
                    </div>
                    {/* <Button radius="full" size="sm">Saber más</Button> */}
                  </Bounce>
                </CardFooter>
              </Card>
            </swiper-slide>

            <swiper-slide>
              <Card isFooterBlurred className="w-full h-[500px] col-span-12 sm:col-span-7">
                <CardHeader className="absolute z-10 top-1 flex-col items-start">
                  <Bounce right duration={600} delay={1000}>
                    <p className="text-tiny px-4 text-white/60 uppercase font-bold">NovaSyncMKT</p>
                  </Bounce>
                  <Bounce left duration={700} delay={1400}>
                    <h4 className="text-white/90 px-4 font-medium text-4xl">Diseño gráfico</h4>
                  </Bounce>
                  <Bounce right duration={800} delay={1800}>
                    <p className="text-sm px-12 pt-6 text-white/90 uppercase font-bold">Estamos dedicados a dar vida a tus ideas a través del diseño gráfico creativo y profesional. Ya sea que necesites una nueva identidad visual, materiales impresos impactantes o gráficos digitales atractivos, nuestro equipo de diseñadores está aquí para hacer que tu marca se destaque</p>
                  </Bounce>
                </CardHeader>
                <Bounce right duration={1000} delay={2200}>
                  <Image
                    removeWrapper
                    style={{ backgroundColor: 'black', opacity: 0.25 }}
                    alt="Relaxing app background"
                    className="z-0 w-full h-full object-cover"
                    src="/resources/images/novasyncmkt-diseno-grafico.webp"
                  />
                </Bounce>
                <CardFooter className="absolute px-12 bg-black/40 bottom-0 z-10 border-t-1 border-default-600 dark:border-default-100">
                  <Bounce right duration={1000} delay={2200}>
                    <div className="flex flex-grow gap-2 items-center">
                      <Image
                        alt="Breathing app icon"
                        className="rounded-full w-10 h-11 bg-black"
                        src="/resources/images/white-icon.png"
                      />
                      <div className="flex flex-col">
                        <p className="text-tiny text-white/60"></p>
                        <p className="text-tiny text-white/60"></p>
                      </div>
                    </div>
                    {/* <Button radius="full" size="sm">Saber más</Button> */}
                  </Bounce>
                </CardFooter>
              </Card>
            </swiper-slide>

            <swiper-slide>
              <Card isFooterBlurred className="w-full h-[500px] col-span-12 sm:col-span-7">
                <CardHeader className="absolute z-10 top-1 flex-col items-start">
                  <Bounce right duration={600} delay={1000}>
                    <p className="text-tiny px-4 text-white/60 uppercase font-bold">NovaSyncMKT</p>
                  </Bounce>
                  <Bounce left duration={700} delay={1400}>
                    <h4 className="text-white/90 px-4 font-medium text-4xl">Branding</h4>
                  </Bounce>
                  <Bounce right duration={800} delay={1800}>
                    <p className="text-sm px-12 pt-6 text-white/90 uppercase font-bold">Entendemos que una marca sólida es fundamental para el éxito de cualquier negocio. Nuestros servicios de branding están diseñados para ayudarte a definir, desarrollar y fortalecer la identidad de tu marca, asegurando que cada aspecto de tu negocio reflejen tu visión y atraiga a tu audiencia ideal</p>
                  </Bounce>
                </CardHeader>
                <Bounce right duration={1000} delay={2200}>
                  <Image
                    removeWrapper
                    style={{ backgroundColor: 'black', opacity: 0.25 }}
                    alt="Relaxing app background"
                    className="z-0 w-full h-full object-cover"
                    src="/resources/images/novasyncmkt-branding.webp"
                  />
                </Bounce>
                <CardFooter className="absolute px-12 bg-black/40 bottom-0 z-10 border-t-1 border-default-600 dark:border-default-100">
                  <Bounce right duration={1000} delay={2200}>
                    <div className="flex flex-grow gap-2 items-center">
                      <Image
                        alt="Breathing app icon"
                        className="rounded-full w-10 h-11 bg-black"
                        src="/resources/images/white-icon.png"
                      />
                      <div className="flex flex-col">
                        <p className="text-tiny text-white/60"></p>
                        <p className="text-tiny text-white/60"></p>
                      </div>
                    </div>
                    {/* <Button radius="full" size="sm">Saber más</Button> */}
                  </Bounce>
                </CardFooter>
              </Card>
            </swiper-slide>

            <swiper-slide>
              <Card isFooterBlurred className="w-full h-[500px] col-span-12 sm:col-span-7">
                <CardHeader className="absolute z-10 top-1 flex-col items-start">
                  <Bounce right duration={600} delay={1000}>
                    <p className="text-tiny px-4 text-white/60 uppercase font-bold">NovaSyncMKT</p>
                  </Bounce>
                  <Bounce left duration={700} delay={1400}>
                    <h4 className="text-white/90 px-4 font-medium text-4xl">Desarrollo Web</h4>
                  </Bounce>
                  <Bounce right duration={800} delay={1800}>
                    <p className="text-sm px-12 pt-6 text-white/90 uppercase font-bold">En NovaSync, convertimos tus ideas en realidades digitales. Nuestro equipo de expertos en desarrollo web está dedicado a crear sitios web impresionantes, funcionales y adaptados a tus necesidades específicas. Ya sea que necesites un sitio web corporativo, una tienda en línea o una plataforma personalizada, estamos aquí para ayudarte a alcanzar el éxito en línea.</p>
                  </Bounce>
                </CardHeader>
                <Bounce right duration={1000} delay={2200}>
                  <Image
                    removeWrapper
                    style={{ backgroundColor: 'black', opacity: 0.25 }}
                    alt="Relaxing app background"
                    className="z-0 w-full h-full object-cover"
                    src="/resources/images/novasyncmkt-web.webp"
                  />
                </Bounce>
                <CardFooter className="absolute px-12 bg-black/40 bottom-0 z-10 border-t-1 border-default-600 dark:border-default-100">
                  <Bounce right duration={1000} delay={2200}>
                    <div className="flex flex-grow gap-2 items-center">
                      <Image
                        alt="Breathing app icon"
                        className="rounded-full w-10 h-11 bg-black"
                        src="/resources/images/white-icon.png"
                      />
                      <div className="flex flex-col">
                        <p className="text-tiny text-white/60"></p>
                        <p className="text-tiny text-white/60"></p>
                      </div>
                    </div>
                    {/* <Button radius="full" size="sm">Saber más</Button> */}
                  </Bounce>
                </CardFooter>
              </Card>
            </swiper-slide>

            <swiper-slide>
              <Card isFooterBlurred className="w-full h-[500px] col-span-12 sm:col-span-7">
                <CardHeader className="absolute z-10 top-1 flex-col items-start">
                  <Bounce right duration={600} delay={1000}>
                    <p className="text-tiny px-4 text-white/60 uppercase font-bold">NovaSyncMKT</p>
                  </Bounce>
                  <Bounce left duration={700} delay={1400}>
                    <h4 className="text-white/90 px-4 font-medium text-4xl">Desarrollo de E-commerce</h4>
                  </Bounce>
                  <Bounce right duration={800} delay={1800}>
                    <p className="text-sm px-12 pt-6 text-white/90 uppercase font-bold">Lleva tu negocio al siguiente nivel con una tienda en línea robusta y segura. Integramos soluciones de pago, gestión de inventarios y herramientas de marketing para que puedas vender tus productos y servicios de manera eficiente y sin complicaciones</p>
                  </Bounce>
                </CardHeader>
                <Bounce right duration={1000} delay={2200}>
                  <Image
                    removeWrapper
                    style={{ backgroundColor: 'black', opacity: 0.25 }}
                    alt="Relaxing app background"
                    className="z-0 w-full h-full object-cover"
                    src="/resources/images/novasyncmkt-ecommerce.webp"
                  />
                </Bounce>
                <CardFooter className="absolute px-12 bg-black/40 bottom-0 z-10 border-t-1 border-default-600 dark:border-default-100">
                  <Bounce right duration={1000} delay={2200}>
                    <div className="flex flex-grow gap-2 items-center">
                      <Image
                        alt="Breathing app icon"
                        className="rounded-full w-10 h-11 bg-black"
                        src="/resources/images/white-icon.png"
                      />
                      <div className="flex flex-col">
                        <p className="text-tiny text-white/60"></p>
                        <p className="text-tiny text-white/60"></p>
                      </div>
                    </div>
                    {/* <Button radius="full" size="sm">Saber más</Button> */}
                  </Bounce>
                </CardFooter>
              </Card>
            </swiper-slide>

            <swiper-slide>
              <Card isFooterBlurred className="w-full h-[500px] col-span-12 sm:col-span-7">
                <CardHeader className="absolute z-10 top-1 flex-col items-start">
                  <Bounce right duration={600} delay={1000}>
                    <p className="text-tiny px-4 text-white/60 uppercase font-bold">NovaSyncMKT</p>
                  </Bounce>
                  <Bounce left duration={700} delay={1400}>
                    <h4 className="text-white/90 px-4 font-medium text-4xl">Optimización para Motores de Búsqueda (SEO)</h4>
                  </Bounce>
                  <Bounce right duration={800} delay={1800}>
                    <p className="text-sm px-12 pt-6 text-white/90 uppercase font-bold">No basta con tener un sitio web hermoso; también necesitas que te encuentren. Implementamos estrategias avanzadas de SEO para mejorar tu visibilidad en los motores de búsqueda y atraer tráfico orgánico de calidad</p>
                  </Bounce>
                </CardHeader>
                <Bounce right duration={1000} delay={2200}>
                  <Image
                    removeWrapper
                    style={{ backgroundColor: 'black', opacity: 0.25 }}
                    alt="Relaxing app background"
                    className="z-0 w-full h-full object-cover"
                    src="/resources/images/novasyncmkt-seo.webp"
                  />
                </Bounce>
                <CardFooter className="absolute px-12 bg-black/40 bottom-0 z-10 border-t-1 border-default-600 dark:border-default-100">
                  <Bounce right duration={1000} delay={2200}>
                    <div className="flex flex-grow gap-2 items-center">
                      <Image
                        alt="Breathing app icon"
                        className="rounded-full w-10 h-11 bg-black"
                        src="/resources/images/white-icon.png"
                      />
                      <div className="flex flex-col">
                        <p className="text-tiny text-white/60"></p>
                        <p className="text-tiny text-white/60"></p>
                      </div>
                    </div>
                    {/* <Button radius="full" size="sm">Saber más</Button> */}
                  </Bounce>
                </CardFooter>
              </Card>
            </swiper-slide>

          </swiper-container>
        </section>
      </RevealOnScroll>

      {/* somos */}
      <RevealOnScroll>
        <section className="py-24 mx-auto max-w-screen-xl px-5 md:px-0" id='somos'>
          <Slide direction='left'>
            <h2 className='text-4xl md:text-7xl'>¿Quienes somos?</h2>
          </Slide>

          <div className="mx-auto max-w-screen-xl pt-12 flex-none md:flex flex-row gap-4">
            <div className='basis-3/4'>
              <Fade duration={100} delay={100}>
                <p className="text-xl md:text-4xl">Nova Marketing Sync nace del concepto de </p>
              </Fade>
              <Fade duration={200} delay={200}>
                <p className="text-xl md:text-4xl">una supernova, un elemento en el espacio </p>
              </Fade>
              <Fade duration={400} delay={400}>
                <p className="text-xl md:text-4xl">el cual es producido por una explosion con </p>
              </Fade>
              <Fade duration={600} delay={600}>
                <p className="text-xl md:text-4xl">la finalidad de crear algo nuevo en el universo. </p>
              </Fade>
              <Fade duration={800} delay={800}>
                <p className="text-xl md:text-4xl">Como agencia, nuestra misión es crear e </p>
              </Fade>
              <Fade duration={1000} delay={1000}>
                <p className="text-xl md:text-4xl">innovar dentro el mercado haciendo </p>
              </Fade>
              <Fade duration={1200} delay={1200}>
                <p className="text-xl md:text-4xl">destacar a tu marca de manera excepcional.</p>
              </Fade>
            </div>

            <div className='basis-1/4 content-center'>
              <Reveal>
                <Image
                  isZoomed
                  className='h-28 max-w-full pt-5 md:pt-0 md:h-auto md:max-w-full rounded-lg'
                  alt="NextUI Fruit Image with Zoom"
                  src="/resources/images/novasyncmkt-logo-white.png"
                />
              </Reveal>
            </div>
          </div>

          {/* <JackInTheBox className='pb-14'>
            <h2 className='text-4xl'>¿Por qué escogernos?</h2>
          </JackInTheBox>
          
          <div className=''>
            <Timeline horizontal>

              <Timeline.Item className='w-full md:w-1/3'>
                <Fade duration={100} delay={0}>
                  <Timeline.Point icon={HiStar} />
                  <Timeline.Content className='pt-6'>
                    <Timeline.Title className='text-white'>Agencia integral especializada</Timeline.Title>
                    <Timeline.Body className='text-white'>
                      <p>Nos enfocamos en marketing, desarrollo web y diseño gráfico para ofrecer soluciones únicas. No solo pensamos fuera de la caja, si no que pensamos fuera de esta galaxia</p>
                    </Timeline.Body>
                  </Timeline.Content>
                </Fade>
              </Timeline.Item>

              <Timeline.Item className='w-full md:w-1/3'>
                <Fade duration={300} delay={600}>
                  <Timeline.Point icon={HiSparkles} />
                  <Timeline.Content className='pt-6'>
                    <Timeline.Title className='text-white'>Estrategias que elevan tu marca</Timeline.Title>
                    <Timeline.Body className='text-white'>
                      <p>Creamos estrategias efectivas para impulsar y hacer despegar más allá de la estratosfera</p>
                    </Timeline.Body>
                  </Timeline.Content>
                </Fade>
              </Timeline.Item>

              <Timeline.Item className='w-full md:w-1/3'>
                <Fade duration={300} delay={900}>
                  <Timeline.Point icon={HiMoon} />
                  <Timeline.Content className='pt-6'>
                    <Timeline.Title className='text-white'>Marketing extraordinario</Timeline.Title>
                    <Timeline.Body className='text-white'>
                      <p>Generamos ideas creativas y únicas que destacan tu marca por encima de la competencia, asegurando un impacto memorable</p>
                    </Timeline.Body>
                  </Timeline.Content>
                </Fade>
              </Timeline.Item>

            </Timeline>
          </div> */}
        </section>
      </RevealOnScroll>

      {/* portfolio   */}
      <RevealOnScroll>
        <section className="py-12 md:py-24 px-4 mx-auto max-w-screen-xl" id='portfolio'>
          <Slide>
            <h2 className='text-4xl md:text-7xl'>Marcas que han despegado al espacio</h2>
          </Slide>

          {/* <Slide direction='right'>
            <p className="text-xl md:text-2xl pt-6">Abrazamos la creatividad en cada proyecto, buscando constantemente nuevas formas de destacar tu marca en el vasto mundo digital</p>
          </Slide> */}

          <div class="py-12 grid grid-cols-2 md:grid-cols-3 gap-4">

            <Reveal>
              <Image
                isZoomed
                className='h-auto max-w-full rounded-lg'
                alt="NextUI Fruit Image with Zoom"
                src="/resources/images/portfolio/1.webp"
              />
            </Reveal>

            <Reveal>
              <Image
                isZoomed
                className='h-auto max-w-full rounded-lg'
                alt="NextUI Fruit Image with Zoom"
                src="/resources/images/portfolio/2.webp"
              />
            </Reveal>

            <Reveal>
              <Image
                isZoomed
                className='h-auto max-w-full rounded-lg'
                alt="NextUI Fruit Image with Zoom"
                src="/resources/images/portfolio/3.webp"
              />
            </Reveal>

            <Reveal>
              <Image
                isZoomed
                className='h-auto max-w-full rounded-lg'
                alt="NextUI Fruit Image with Zoom"
                src="/resources/images/portfolio/4.webp"
              />
            </Reveal>

            <Reveal>
              <Image
                isZoomed
                className='h-auto max-w-full rounded-lg'
                alt="NextUI Fruit Image with Zoom"
                src="/resources/images/portfolio/5.webp"
              />
            </Reveal>

            <Reveal>
              <Image
                isZoomed
                className='h-auto max-w-full rounded-lg'
                alt="NextUI Fruit Image with Zoom"
                src="/resources/images/portfolio/6.webp"
              />
            </Reveal>
          </div>

        </section>
      </RevealOnScroll>

      {/* contacto */}
      <RevealOnScroll>
        <section className='py-24 px-4 mx-auto max-w-screen-xl' id='contacto'>
          <Reveal>
            <h2 className='text-4xl lg:text-8xl xl:text-8xl'>
              Conéctate con Nuestra Base de Misión
            </h2>
            <p className='pt-6 text-xl md:text-2xl'>
              ¿Listo para lanzar tu próximo proyecto de marketing? estamos preparados para embarcarnos en esta misión contigo. Ya sea que necesites asesoramiento estratégico, soporte técnico, o simplemente quieras saber más sobre nuestros servicios, estamos a un mensaje de distancia. Completa el formulario a continuación y nos pondremos en contacto contigo a la velocidad de la luz.
            </p>
          </Reveal>
          <div class="px-5 grid gap-8 grid-cols-1 md:grid-cols-2 py-24 mx-auto rounded-lg">
            <div class="flex flex-col justify-center">
              <Zoom direction='left'>
                <div class="text-center">
                  <Image
                    removeWrapper
                    alt="Relaxing app background"
                    className="z-0 w-full object-cover"
                    src="/resources/images/novasyncmkt-astronout.webp"
                  />
                </div>
              </Zoom>
            </div>
            <Zoom direction='right'>
              <form className='pt-8' onSubmit={handleOnSubmit}>
                <div>
                  <Input
                    isRequired
                    type="text"
                    label="Nombre"
                    size='lg'
                    placeholder="Alberto Martinez"
                    className="w-full"
                    name='name'
                  />
                </div>
                <div class="mt-8">
                  <Input
                    isRequired
                    type="email"
                    label="Email"
                    size='lg'
                    placeholder="novasync@novasync.com"
                    className="w-full"
                    name='email'
                  />
                </div>
                <div class="mt-8">
                  <Input
                    isRequired
                    type="text"
                    label="Teléfono"
                    size='lg'
                    placeholder="99 9999 9999"
                    className="w-full"
                    name='tel'
                  />
                </div>
                <div class="mt-8">
                  <Textarea
                    variant="faded"
                    label="Mensaje"
                    size='lg'
                    placeholder="Ingresa la descripción de tu proyecto"
                    description="Cuentanos más sobre tu proyecto"
                    className="w-full"
                    name='message'
                  />
                </div>
                <div class="mt-8">
                  <Button type='submit' color="primary" variant="shadow">
                    Enviar Mensaje
                  </Button>
                </div>
              </form>
            </Zoom>
          </div>
        </section>
      </RevealOnScroll>

      {/* footer */}
      <RevealOnScroll>
        <section className="mx-auto max-w-screen-xl">
          <Slide>
            <Footer container>
              <div className="w-full">
                <div className="grid w-full justify-between sm:flex sm:justify-between md:flex md:grid-cols-1">
                  <div>
                    <FooterBrand
                      href="#home"
                      src="/resources/images/novasyncmkt-logo-white.png"
                      alt="NovaSync Marketing Logo"
                      name=""
                      className='h-12'
                    />
                  </div>
                  <div className="grid grid-cols-2 gap-8 sm:mt-4 sm:grid-cols-3 sm:gap-6">
                    <div>
                      <FooterTitle className='text-white' title="Servicios" />
                      {/* <FooterLinkGroup col>
                      <FooterLink className='text-white' href="#">¿Quienes Somos?</FooterLink>
                      <FooterLink className='text-white' href="#">Servicios</FooterLink>
                    </FooterLinkGroup> */}
                    </div>
                    <div>
                      <FooterTitle className='text-white' title="NovaSync" />
                      {/* <FooterLinkGroup col>
                      <FooterLink className='text-white' href="#">Github</FooterLink>
                      <FooterLink className='text-white' href="#">Discord</FooterLink>
                    </FooterLinkGroup> */}
                    </div>
                    <div>
                      <FooterTitle className='text-white' title="Contacto" />
                      {/* <FooterLinkGroup col>
                      <FooterLink className='text-white' href="#">Privacy Policy</FooterLink>
                      <FooterLink className='text-white' href="#">Terms &amp; Conditions</FooterLink>
                    </FooterLinkGroup> */}
                    </div>
                  </div>
                </div>
                <FooterDivider />
                <div className="w-full sm:flex sm:items-center sm:justify-between">
                  <FooterCopyright className='text-white' href="#" by="NovaSync Marketing" year={2024} />
                  <div className="mt-4 flex space-x-6 sm:mt-0 sm:justify-center">
                    <FooterIcon className='text-white' href="#" icon={BsFacebook} />
                    <FooterIcon className='text-white' href="#" icon={BsInstagram} />
                    <FooterIcon className='text-white' href="#" icon={BsTwitter} />
                    <FooterIcon className='text-white' href="#" icon={BsTiktok} />
                    <FooterIcon className='text-white' href="#" icon={BsWhatsapp} />
                  </div>
                </div>
              </div>
            </Footer>
          </Slide>
        </section>
      </RevealOnScroll>

    </div >
  );
}

export default App;
